(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/tipsen-shared/assets/javascripts/tipsen-shared.js') >= 0) return;  svs.modules.push('/components/sport/tipsen-shared/assets/javascripts/tipsen-shared.js');
"use strict";

var _svs;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

let sportInfoConstants;
let sportTypes;
let tipsenSharedConstants;
let bitmapUtils;
let productIds;
if (svs.isServer) {
  const trinidad = require('trinidad-core');
  sportInfoConstants = require('../../../../sportinfo/common/assets/javascripts/constants.es6');
  sportTypes = require('../../../sport-types/assets/javascripts/sport-types.es6');
  tipsenSharedConstants = require('./constants.es6');
  bitmapUtils = require('./bitmap.es6');
  productIds = trinidad.core.components.require('utils', 'products').productIds;
} else {
  sportInfoConstants = svs.components.sportinfo.common.constants;
  sportTypes = svs.components.sport.sportTypes;
  tipsenSharedConstants = svs.components.sport.tipsenShared.constants;
  bitmapUtils = svs.components.sport.tipsenShared.bitmap;
  productIds = svs.utils.products.productIds;
}
const {
  getBinaryNumbers,
  getBitmapIndices
} = bitmapUtils;
const {
  SportTypes
} = sportTypes;
const {
  EventTypeId
} = sportInfoConstants;
const {
  SvsContactPhoneNumber
} = tipsenSharedConstants;
const CouponQueryParams = {
  PARAM_BET_AMOUNT: 'bet_amount',
  PARAM_OUTCOME_PAIRS: 'outcomePairs',
  PARAM_WEEK: 'week',
  PARAM_SPORTKRYSS: 'sportkryss',
  PARAM_SPORTKRYSS_NUMBER: 'sportkryss_number',
  PARAM_SIGNS: 'signs',
  PARAM_SYSTEM_TYPE: 'system_type',
  PARAM_SYSTEM: 'system',
  PARAM_SHARE: 'share',
  PARAM_PIX_SYSTEM: 'pix_system',
  PARAM_REDUCTION: 'reduction'
};
const PARAM_ACTION = 'action';
const PARAM_AMOUNT = 'amount';
const PARAM_DRAWNUMBER = 'draw';
const PARAM_EVENT_NUMBER = 'event';
const PARAM_IGNORE_MODIFIED_RESULT = 'mr_ignore';
const PARAM_MARKETPLACE_GAME_ID = 'activatedDrawId';
const PARAM_PRODUCT_ID = 'product';
const SubProductId = {
  NONE: 0,
  EUROPATIPSET: 1,
  BRASILIANSKA_TIPSET: 2,
  VM_TIPSET: 3,
  EM_TIPSET: 4,
  VINN2: 5,
  VINN3: 6,
  VINN4: 7,
  VINN5: 8,
  RX1W: 9,
  RX1P: 10,
  RX2AO: 11,
  RX2IO: 12,
  RX2P: 13,
  RX5IO: 14,
  RX4IO: 15,
  RX3AO: 16,
  RX3IO: 17,
  RX3IOAO: 18,
  RX2P4: 19,
  RX1WINT: 20,
  RX2IOINT: 21,
  RX3IOINT: 22
};
const ClientSubProductType = {
  NONE: 'None',
  VM_TIPSET: 'VmTipset',
  EM_TIPSET: 'EmTipset',
  VM_EUROPA: 'VmEuropa',
  EM_EUROPA: 'EmEuropa',
  TIPS_EM: 'TipsEM',
  TIPS_SM: 'TipsSM',
  EUROPATIPSET: 'Europatipset'
};
const DrawState = {
  Undefined: 0,
  Defined: 1,
  Opened: 2,
  Regclosed: 3,
  Cancelclosed: 4,
  ResultEntered: 5,
  ResultVerified: 6,
  WincountStarted: 7,
  WincountFailed: 8,
  WincountDone: 9,
  WinvaluesEntered: 10,
  WinvaluesVerified: 11,
  WingenStarted: 12,
  WingenFailed: 13,
  WingenDone: 14,
  WinpayoutStarted: 15,
  WinpayoutFailed: 16,
  PublishStarted: 17,
  PublishFailed: 18,
  PublishDone: 19,
  Finalized: 20
};
const drawStateEnums = {
  OPEN: 'open',
  LIVE: 'live',
  FINALIZED: 'finalized'
};
const DisableType = {
  Unused: 'Unused',
  NotDisabled: 'NotDisabled',
  Regbet: 'Regbet',
  Cancelbet: 'Cancelbet',
  Validation: 'Validation',
  AutoActions: 'AutoActions'
};
const UserProduct = {
  EmTipset: 'EmTipset',
  VmTipset: 'VmTipset'
};
const subProductId2ToDisplayName = {
  [SubProductId.EUROPATIPSET]: 'Europatipset',
  [SubProductId.EM_TIPSET]: 'EM-Tipset',
  [SubProductId.VM_TIPSET]: 'VM-Tipset'
};
const userProduct2SubProductId = {
  Europatipset: SubProductId.EUROPATIPSET,
  EmTipset: SubProductId.EM_TIPSET,
  VmTipset: SubProductId.VM_TIPSET
};
const getSubProductDisplayName = subProductId => {
  const displayName = subProductId2ToDisplayName[subProductId];
  if (!displayName) {
    return '';
  }
  return displayName;
};
const signIndexToOutcome = {
  [EventTypeId.EVENT_1X2]: {
    0: '1',
    1: 'X',
    2: '2'
  },
  [EventTypeId.GOAL_COUNT]: {
    0: '0',
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
    6: '6',
    7: '7'
  },
  [EventTypeId.RESULT]: {
    0: '0',
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
    6: '6',
    7: '7',
    8: '8',
    9: '9',
    10: '10'
  },
  [EventTypeId.HEAD_TO_HEAD]: {
    0: '1',
    1: 'X',
    2: '2'
  },
  undefined: {
    0: '1',
    1: 'X',
    2: '2'
  }
};
const signIndexToTitle = {
  [EventTypeId.EVENT_1X2]: _objectSpread({}, signIndexToOutcome[EventTypeId.EVENT_1X2]),
  [EventTypeId.GOAL_COUNT]: _objectSpread(_objectSpread({}, signIndexToOutcome[EventTypeId.GOAL_COUNT]), {}, {
    7: '7+'
  }),
  [EventTypeId.RESULT]: _objectSpread(_objectSpread({}, signIndexToOutcome[EventTypeId.RESULT]), {}, {
    10: '10+'
  })
};
const outcomeToSignIndex = {
  [EventTypeId.EVENT_1X2]: {
    1: 0,
    X: 1,
    2: 2
  },
  [EventTypeId.GOAL_COUNT]: {
    0: 0,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7
  },
  [EventTypeId.RESULT]: {
    0: 0,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: 9,
    10: 10,
    11: 11,
    12: 12,
    13: 13,
    14: 14,
    15: 15,
    16: 16,
    17: 17,
    18: 18,
    19: 19,
    20: 20,
    21: 21,
    22: 22,
    23: 23,
    24: 24,
    25: 25,
    26: 26,
    27: 27,
    28: 28,
    29: 29,
    30: 30,
    31: 31,
    32: 32,
    33: 33,
    34: 34,
    35: 35,
    36: 36,
    37: 37,
    38: 38,
    39: 39,
    40: 40,
    41: 41,
    42: 42,
    43: 43,
    44: 44,
    45: 45,
    46: 46,
    47: 47,
    48: 48,
    49: 49,
    50: 50,
    51: 51,
    52: 52,
    53: 53,
    54: 54,
    55: 55
  }
};
const MSignString = 'M';
const USignString = 'U';
const rowsStringDelimiter = ',';
const rowStringDelimiter = ':';
const signsStringDelimiter = ';';
const convertSignsToString = eventTypeId => {
  const eventSupportsSystems = eventTypeId === EventTypeId.EVENT_1X2;
  return (signStrings, uRow) => signStrings.map((signs, index) => {
    var _signs;
    const isM = ((_signs = signs[signs.length - 1]) === null || _signs === void 0 ? void 0 : _signs.toUpperCase()) === MSignString;
    if (isM) {
      signs = signs.slice(0, -1);
    }
    const rowNumber = "".concat(index + 1);
    const rowMSign = eventSupportsSystems && isM ? MSignString : '';
    const rowSigns = signs.toUpperCase().split('').map(sign => {
      const uPrefix = eventSupportsSystems && !isM && Array.isArray(uRow) && uRow[index] === sign ? USignString : '';
      return uPrefix + sign;
    }).join('');
    return "".concat(rowNumber).concat(rowStringDelimiter).concat(rowMSign).concat(rowSigns);
  }).join(rowsStringDelimiter);
};
const convert1X2SignsTo1X2String = convertSignsToString(EventTypeId.EVENT_1X2);
const convertAntmalSignsToAntmalString = convertSignsToString(EventTypeId.GOAL_COUNT);
const getSystemMSign = mSign => {
  if (mSign) {
    return MSignString;
  }
  return '';
};
const getSystemUSign = (uSign, index) => {
  if (Number.isInteger(uSign) && uSign === index) {
    return USignString;
  }
  return '';
};
const convertArrToString = (eventTypeId, signDelimiter) => {
  const eventSupportsSystems = eventTypeId === EventTypeId.EVENT_1X2;
  return function (signs) {
    let uRow = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    let mSigns = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
    return signs.map((s, index) => {
      const canIncludeSystemSign = eventSupportsSystems && s.length > 2;
      const mSign = canIncludeSystemSign ? getSystemMSign(mSigns[index]) : '';
      const concatenatedSigns = s.map((v, i2) => {
        if (v) {
          const uSign = canIncludeSystemSign ? getSystemUSign(uRow[index], i2) : '';
          return "".concat(uSign).concat(signIndexToOutcome[eventTypeId][i2]);
        }
        return false;
      }).filter(Boolean).join(signDelimiter);
      if (concatenatedSigns === '') {
        return false;
      }
      return "".concat(index + 1).concat(rowStringDelimiter).concat(mSign).concat(concatenatedSigns);
    }).filter(Boolean).join(rowsStringDelimiter);
  };
};
const convertSignsTo1X2String = convertArrToString(EventTypeId.EVENT_1X2, signsStringDelimiter);
const convertSignsToAntmalString = convertArrToString(EventTypeId.GOAL_COUNT, signsStringDelimiter);
const convertSignsToResultString = signs => {
  let rowNumber = 1;
  const outcomesString = signs.map(eventSigns => {
    const eventOutcomes = eventSigns.map(eventGroupSigns => {
      const outcomesArray = getBitmapIndices(eventGroupSigns).flat();
      const signsString = outcomesArray.join(signsStringDelimiter);
      let rowString;
      if (signsString) {
        rowString = "".concat(rowNumber).concat(rowStringDelimiter).concat(signsString);
      }
      rowNumber += 1;
      return rowString;
    }).filter(Boolean);
    if (eventOutcomes.length) {
      return eventOutcomes;
    }
    return false;
  }).filter(Boolean).join(rowsStringDelimiter);
  return outcomesString;
};
const convertReductionValueToString = {
  1: 'H',
  2: 'O',
  4: 'B' 
};
const convertReductionStringToVal = {
  H: 1,
  O: 2,
  B: 4
};
const convertReductionToString = (reduction, productReduction) => {
  if (!reduction || !reduction.length) {
    return;
  }
  const reductionSum = productReduction.reduce((sum, r) => r + sum, 0);
  const returnString = reduction.map((eventReduction, index) => {
    if (eventReduction === reductionSum) {
      return false;
    }
    const invertedSelectedReduction = getBinaryNumbers(eventReduction);
    const selectedReduction = productReduction.filter(r => !invertedSelectedReduction.includes(r));
    const outcomeString = selectedReduction.map(reduction => convertReductionValueToString[reduction]).join('');
    return "".concat(index + 1).concat(rowStringDelimiter).concat(outcomeString);
  }).filter(Boolean).join(rowsStringDelimiter);
  return returnString;
};
const TipsenModuleGameUrl = {
  COMPETITIONS: 'tavlingar',
  COUPON: 'kupong',
  EXTERNAL: 'externa-systemspel',
  GAME_GUIDE: 'spelguide',
  MARKETPLACE_TEAM: 'lagspel',
  MARKETPLACE: 'andelsspel',
  MY_BETS: 'mina-spel',
  MY_BETS2: 'mina-spel2',
  ODDS: 'odds',
  PIX: 'pix',
  QUICK_BET: 'oddset',
  RELATED: 'relaterat',
  RESULT: 'resultat',
  SINGLES: 'enkelrader',
  STANDARD: '',
  SYSTEM: 'systemspel',
  TIPS_EM: 'europamastaren',
  TIPS_SM: 'tips-sm',
  YOUR_COUPON: 'din-kupong'
};
const isHvs = ((_svs = svs) === null || _svs === void 0 || (_svs = _svs.core) === null || _svs === void 0 || (_svs = _svs.data) === null || _svs === void 0 ? void 0 : _svs.module) === 'hvs';
const ReducerState = {
  Init: 'REDUCER_STATE_INIT',
  Pending: 'REDUCER_STATE_PENDING',
  Rejected: 'REDUCER_STATE_REJECTED',
  Done: 'REDUCER_STATE_DONE'
};
const AddonProductIds = {
  SPORTJOKER: 'SPORTJOKER'
};
const isAddonWagerByITSName = ITSProductId => Boolean(AddonProductIds[ITSProductId]);

const getOutcomeCount = (eventTypeId, sportType, productId) => {
  if (eventTypeId === EventTypeId.GOAL_COUNT) {
    return sportType === SportTypes.Hockey ? 11 : 8;
  }
  if (eventTypeId === EventTypeId.RESULT) {
    if (productId === productIds.MATCHEN) {
      return 56;
    }
    return 11;
  }
  if (eventTypeId === EventTypeId.OVER_UNDER) {
    return 2;
  }
  return 3;
};
const getOutcomesForEventType = function (eventTypeId, sportType) {
  let sign = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  const outcomeCount = getOutcomeCount(eventTypeId, sportType);
  return new Array(outcomeCount).fill(sign);
};
const getGoalCount = (eventType, sportType) => {
  const emptySigns = getOutcomesForEventType(eventType, sportType, undefined);
  const outcomeLabels = emptySigns.map((_, i) => ({
    text: "".concat(i),
    sign: i
  }));
  return outcomeLabels.slice(0, -1).concat({
    text: "".concat(outcomeLabels.slice(-1)[0].text, "+"),
    ariaLabel: "".concat(outcomeLabels.slice(-1)[0].text, " eller fler"),
    sign: parseInt(outcomeLabels.slice(-1)[0].text, 10)
  });
};
const goalCountObj = {
  [SportTypes.Hockey]: getGoalCount(EventTypeId.GOAL_COUNT, SportTypes.Hockey),
  [SportTypes.Soccer]: getGoalCount(EventTypeId.GOAL_COUNT, SportTypes.Soccer)
};
const emptyGoalCount = [];
const labels1X2 = [{
  text: '1',
  sign: '1'
}, {
  text: 'X',
  ariaLabel: 'Kryss',
  sign: 'X'
}, {
  text: '2',
  sign: '2'
}];
const labelsOverUnder = [{
  text: 'Över'
}, {
  text: 'Under'
}];
const getResultOutcome = (eventTypeId, sportType, productId) => {
  const emptySigns = getOutcomeCount(eventTypeId, sportType, productId);
  const array = new Array(emptySigns).fill(false);
  const outcomeLabels = array.map((_, i) => ({
    text: "".concat(i),
    sign: i
  }));
  if (productId !== productIds.MATCHEN) {
    return outcomeLabels.slice(0, -1).concat({
      text: "".concat(outcomeLabels.slice(-1)[0].text, "+"),
      ariaLabel: "".concat(outcomeLabels.slice(-1)[0].text, " eller fler"),
      sign: parseInt(outcomeLabels.slice(-1)[0].text, 10)
    });
  }
  return outcomeLabels;
};
const getOutcomeLabelsForEventType = (eventType, sportType, productId) => {
  if (eventType === EventTypeId.GOAL_COUNT) {
    return goalCountObj[sportType] || emptyGoalCount;
  }
  if (eventType === EventTypeId.RESULT) {
    return getResultOutcome(eventType, sportType, productId);
  }
  if (eventType === EventTypeId.OVER_UNDER) {
    return labelsOverUnder;
  }
  return labels1X2;
};
const WagerStatus = {
  Active: 'active',
  Finished: 'finished'
};
const convertStringToSigns = function (oneXtwo, size) {
  let eventTypeId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : EventTypeId.EVENT_1X2;
  const signCount = getOutcomeCount(eventTypeId, SportTypes.Soccer);
  const outcomesToIndex = outcomeToSignIndex[eventTypeId];
  const signs = new Array(size);
  for (let i = 0; i < signs.length; ++i) {
    signs[i] = new Array(signCount).fill(false);
  }
  const uSigns = new Array(size).fill(null);
  const mSigns = new Array(size).fill(false);
  if (outcomesToIndex) {
    const useMSigns = eventTypeId === EventTypeId.EVENT_1X2;
    const useUSigns = eventTypeId === EventTypeId.EVENT_1X2;
    const events = oneXtwo.toUpperCase().split(rowsStringDelimiter);
    for (const event of events) {
      let [eventNumber, v] = event.split(rowStringDelimiter);
      eventNumber = parseInt(eventNumber, 10);
      if (eventNumber > size) {
        continue;
      }
      const zeroIndexEventNumber = eventNumber - 1;
      if (v[0] === MSignString) {
        if (useMSigns) {
          mSigns[zeroIndexEventNumber] = true;
        }
        v = v.slice(1);
      }
      const signSplit = v.indexOf(signsStringDelimiter) >= 0 ? signsStringDelimiter : '';
      const chars = v.split(signSplit);
      let prevSign;
      chars.forEach(currentChars => {
        currentChars.split('').forEach(d => {
          const outcomeIndex = outcomesToIndex[d];
          if (outcomeIndex !== undefined) {
            if (useUSigns && uSigns[zeroIndexEventNumber] === null && !mSigns[zeroIndexEventNumber] && prevSign === USignString) {
              uSigns[zeroIndexEventNumber] = outcomeIndex;
            }
            signs[zeroIndexEventNumber][outcomeIndex] = true;
          }
          prevSign = d;
        });
      });
    }
  }
  return {
    signs,
    mSigns,
    uSigns
  };
};
const outcomeGroupIndexCount = {
  [EventTypeId.EVENT_1X2]: 1,
  [EventTypeId.GOAL_COUNT]: 1,
  [EventTypeId.RESULT]: 2
};
const convertResultStringToSigns = (outcomes, numberOfEvents, eventTypeId, productId) => {
  const signCount = getOutcomeCount(eventTypeId, SportTypes.Soccer, productId);
  const outcomesToIndex = outcomeToSignIndex[eventTypeId];
  const signs = [];
  for (let i = 0; i < numberOfEvents; ++i) {
    signs[i] = [];
    let outcomeGroupIndex = 0;
    for (; outcomeGroupIndex < outcomeGroupIndexCount[eventTypeId];) {
      signs[i][outcomeGroupIndex] = new Array(signCount).fill(false);
      outcomeGroupIndex++;
    }
    outcomeGroupIndex = 0;
  }
  if (outcomesToIndex) {
    const events = outcomes.toUpperCase().split(rowsStringDelimiter);
    for (const event of events) {
      const [eventNumberStr, v] = event.split(rowStringDelimiter);
      const eventNumb = parseInt(eventNumberStr, 10);
      if (!v || !eventNumb) {
        continue;
      }
      let eventNumber = 0;
      let outcomeGroupIndex;
      if (eventNumb % 2) {
        eventNumber = Math.ceil(eventNumb / 2);
        outcomeGroupIndex = 0;
      } else {
        eventNumber = Math.floor(eventNumb / 2);
        outcomeGroupIndex = 1;
      }
      if (eventNumber > numberOfEvents) {
        continue;
      }
      const zeroIndexEventNumber = eventNumber ? eventNumber - 1 : eventNumber;
      const signSplit = v.indexOf(signsStringDelimiter) >= 0 ? signsStringDelimiter : '';
      let chars;
      if (v.length === 2 && outcomesToIndex[v] && JSON.stringify(outcomesToIndex[v]).length > 1) {
        chars = [v];
      } else {
        chars = v.split(signSplit);
      }
      chars.forEach(currentChars => {
        const outcomeIndex = outcomesToIndex[currentChars];
        if (outcomeIndex !== undefined) {
          signs[zeroIndexEventNumber][outcomeGroupIndex][outcomeIndex] = true;
        }
      });
    }
  }
  return {
    signs
  };
};
const convertReductionStringToValue = (reductions, productReductionSum, numberOfEvents) => {
  if (!reductions || !productReductionSum) {
    return;
  }
  const reductionOutcomes = new Array(numberOfEvents).fill(productReductionSum);
  const reductionEvents = reductions.toUpperCase().split(rowsStringDelimiter);
  for (const event of reductionEvents) {
    const [eventNumberStr, value] = event.split(rowStringDelimiter);
    const eventNumber = parseInt(eventNumberStr, 10);
    if (!value || !eventNumber) {
      continue; 
    }
    const chars = value.split('');
    const charsValues = chars.map(char => convertReductionStringToVal[char]);
    const reductionValue = productReductionSum - charsValues.reduce((val, sum) => val + sum, 0);
    if (reductionValue) {
      reductionOutcomes[eventNumber - 1] = reductionValue;
    }
  }
  return reductionOutcomes;
};
const rowToIndexes = (row, nrOfAlternativs) => {
  const data = Array(nrOfAlternativs).fill(false);
  row.forEach(n => {
    if (n === 'F') {
      data[data.length - 1] = true;
    } else {
      data[n] = true;
    }
  });
  return data;
};
const convertToSigns = (boardData, eventTypeId) => boardData.map(data => {
  const outcomesToIndex = outcomeToSignIndex[eventTypeId];
  const norOfAlternatives = getOutcomeCount(data.eventTypeId, data.sportType);
  const outcomeIndex = data.outcomes.length === 2 && JSON.stringify(outcomesToIndex[data.outcomes]).length > 1 ? [data.outcomes] : data.outcomes.split();
  const signs = rowToIndexes(outcomeIndex, norOfAlternatives);
  return signs;
});
const MAX_WIN_DIVS = 4;

const getWinDivs = winresult => {
  const winDivs = [...winresult];
  const firstWinDiv = winDivs.shift();
  const winnersDivs = winDivs.filter(winDiv => winDiv.winners);
  const remainingWinDivCount = Math.max(MAX_WIN_DIVS - 1 - winnersDivs.length, 0);
  const remainingWinDivs = winDivs.slice(0, remainingWinDivCount);
  return [firstWinDiv].concat(remainingWinDivs).concat(winnersDivs).slice(0, MAX_WIN_DIVS);
};
const buttonChoices = {
  GAMES: 'Matcher',
  SYSTEM_DISTRIBUTION: 'Systemfördelning',
  PEOPLE_DISTRIBUTION: 'Svenska Folket'
};
const payoutTargets = {
  BANK: 'Bank',
  RETAILER: 'Ombudsterminal',
  INTERNAL_VALIDATION: 'Intern validering',
  PLAYER_ACCOUNT: 'Spelkontot'
};
const getPayoutDoneText = target => {
  switch (target) {
    case payoutTargets.BANK:
      return 'Vinsten har satts in på ditt bankkonto.';
    case payoutTargets.RETAILER:
    case payoutTargets.INTERNAL_VALIDATION:
      return 'Din vinst är utbetald!';
    case payoutTargets.PLAYER_ACCOUNT:
      return 'Vinsten har satts in på ditt spelkonto.';
    default:
      return "V\xE4nligen kontakta Svenska Spels kundtj\xE4nst, ".concat(SvsContactPhoneNumber);
  }
};
const getComingPayoutText = target => {
  switch (target) {
    case payoutTargets.BANK:
      return '';
    case payoutTargets.RETAILER:
    case payoutTargets.INTERNAL_VALIDATION:
      return 'Du är välkommen att hämta ut din vinst hos valfri spelbutik.';
    case payoutTargets.PLAYER_ACCOUNT:
      return '';
    default:
      return "V\xE4nligen kontakta Svenska Spels kundtj\xE4nst, ".concat(SvsContactPhoneNumber);
  }
};
const getPayoutTextByTarget = (target, payoutDone) => payoutDone ? getPayoutDoneText(target) : getComingPayoutText(target);
const getDrawStateEnum = drawStateId => {
  if (drawStateId < DrawState.Cancelclosed) {
    return drawStateEnums.OPEN;
  } else if (drawStateId >= DrawState.Cancelclosed && drawStateId < DrawState.Finalized) {
    return drawStateEnums.LIVE;
  } else if (drawStateId === DrawState.Finalized) {
    return drawStateEnums.FINALIZED;
  }
};
const actions = {
  PAY: 'pay',
  OPEN_DRAW_SELECTOR: 'open-draw-selector',
  OPEN_PIX: 'open-pix',
  CLOSE_MODAL: 'close-modal'
};
const betslipForceTypes = {
  NONE: 'none',
  BETSLIP: 'betslip',
  MENU: 'menu'
};
const toExport = {
  actions,
  AddonProductIds,
  betslipForceTypes,
  buttonChoices,
  ClientSubProductType,
  convert1X2SignsTo1X2String,
  convertAntmalSignsToAntmalString,
  convertReductionStringToValue,
  convertReductionToString,
  convertResultStringToSigns,
  convertSignsTo1X2String,
  convertSignsToAntmalString,
  convertSignsToResultString,
  convertStringToSigns,
  convertToSigns,
  CouponQueryParams,
  DisableType,
  DrawState,
  drawStateEnums,
  getDrawStateEnum,
  getOutcomeCount,
  getOutcomeLabelsForEventType,
  getPayoutTextByTarget,
  getSubProductDisplayName,
  getWinDivs,
  isAddonWagerByITSName,
  isHvs,
  outcomeToSignIndex,
  PARAM_ACTION,
  PARAM_AMOUNT,
  PARAM_DRAWNUMBER,
  PARAM_EVENT_NUMBER,
  PARAM_IGNORE_MODIFIED_RESULT,
  PARAM_MARKETPLACE_GAME_ID,
  PARAM_PRODUCT_ID,
  payoutTargets,
  ReducerState,
  rowToIndexes,
  signIndexToOutcome,
  signIndexToTitle,
  SubProductId,
  subProductId2ToDisplayName,
  TipsenModuleGameUrl,
  UserProduct,
  userProduct2SubProductId,
  WagerStatus
};
if (svs.isServer) {
  module.exports = toExport;
} else {
  setGlobal('svs.components.sport.tipsenShared', toExport);
}

 })(window);